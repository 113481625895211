import React from 'react';
import logo from '../assets/images/logo.png';

import '../scss/style.scss';

const index = () => {
  return (
    <div>
      <section className="hero gradientBg is-fullheight">
        <div className="hero-body">
          <div className="container center">
            <article className="media">
              <div className="media-content">
                <div className="content">
                  {/* <h1 className="is-uppercase has-text-white" style={{ fontSize: '5em' }}> */}
                  <span className="icon is-large is" style={{ margin: 'auto' }}>
                    <img src={logo} alt="logo" />
                  </span>

                  {/* </h1> */}
                  <p className="subtitle has-text-white is-size-3">
                    The brand that makes you happy
                  </p>
                  <p className="subtitle has-text-white is-size-5">
                    Our new website is under construction.
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="columns">
          <div className="column">
            <iframe
              title="ExhibitorBadge"
              src="https://libs.a2zinc.net/Common/Widgets/ExhibitorBadge.aspx?applicationid=gM+M1z2efQdG+80vAgrVYldily4MF7jwI+voQf0xjKDx/f/3oqsywXi/TjKM5ggR&CompanyID=857347&BoothID=769918&EventID=1367"
              frameBorder="0"
              allowTransparency="true"
              scrolling="no"
              width="200px"
              height="330px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
